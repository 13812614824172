import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import ReactPlayer from 'react-player';
import "./App.css";
import { Plans } from "./components/plans";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation openModal={openModal} />
      <div style={{ width: '100%', overflow: 'hidden', marginBottom: 30 }}>
        <ReactPlayer
          url={"https://firebasestorage.googleapis.com/v0/b/d96-front.appspot.com/o/D96%20landing%20page%20(online-video-cutter.com).mp4?alt=media&token=7582bc7d-a6e4-4068-908e-548a2295330f"}
          controls={true}
          playing={true}
          width="100%"
          height="90vh"
        />
      </div>
      {/* <Header data={landingPageData.Header} /> */}
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Plans data={landingPageData.Testimonials} />
      <Gallery data={landingPageData.Gallery} />
      <Testimonials data={landingPageData.Testimonials} />
      {/* <Team data={landingPageData.Team} /> */}
      <Contact setOpenModal={setOpenModal} data={landingPageData.Contact} />
    </div>
  );
};

export default App;
