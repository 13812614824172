const RefundPolicy = () => {

    return (
        <div style={{ padding: 30 }}>
            <h1>Subscription Refund Policy</h1>
            <p>Last updated: February 02, 2024</p>
            <p>Thank you for choosing D96 and subscribing to our services.</p>
            <p>If, for any reason, You are not entirely satisfied with your subscription, we invite You to review our policy on refunds. This Subscription Refund Policy has been crafted to ensure transparency and fairness in our dealings with customers.</p>
            <p>The following terms apply to any subscriptions You purchase from Us.</p>

            <h4>Interpretation and Definitions</h4>
            <h3>Interpretation</h3>
            <p>Words with initial capitalization have meanings defined under the following conditions. These definitions apply regardless of whether they appear in singular or plural form.</p>

            <h3>Definitions</h3>
            <p>For the purposes of this Subscription Refund Policy:</p>
            <ul>
                <li><p><strong>Company</strong> refers to D96.</p></li>
                <li><p><strong>Service</strong> refers to D96, including the mobile app and admin app.</p></li>
                <li><p><strong>Subscription</strong> refers to the services or access to D96 provided on a periodic basis.</p></li>
                <li><p><strong>You</strong> means the individual accessing or using the Service or the company or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p></li>
            </ul>

            <h4>Subscription Cancellation and Refund Rights</h4>
            <p>You have the right to cancel Your subscription at any time, and we offer a refund under the following conditions:</p>
            <ul>
                <li><p>If You cancel within the first 14 days of your initial subscription, You are eligible for a full refund.</p></li>
                <li><p>If You cancel after the initial 14 days, You will not be eligible for a refund for the current billing period but will not be charged for future periods.</p></li>
            </ul>
            <p>To exercise Your right of cancellation, inform Us of Your decision by:</p>
            <ul>
                <li><p>By email: support@d-96.com</p></li>
            </ul>
            <p>We will process the refund within 14 days of receiving your cancellation request.</p>

            <h3>Contact Us</h3>
            <ul>
                <li><p>By email: support@d-96.com</p></li>
            </ul>
        </div>

        // <div style={{ padding: 30 }}>
        //     <h1>Return and Refund Policy</h1>
        //     <p>Last updated: February 02, 2024</p>
        //     <p>Thank you for choosing D96.</p>
        //     <p>If, for any reason, You are not entirely satisfied with a purchase, we invite You to review our policy on refunds and returns. This Return and Refund Policy has been crafted to ensure transparency and fairness in our dealings with customers.</p>
        //     <p>The following terms apply to any products You purchase from Us.</p>

        //     <h4>Interpretation and Definitions</h4>
        //     <h3>Interpretation</h3>
        //     <p>Words with initial capitalization have meanings defined under the following conditions. These definitions apply regardless of whether they appear in singular or plural form.</p>

        //     <h3>Definitions</h3>
        //     <p>For the purposes of this Return and Refund Policy:</p>
        //     <ul>
        //         <li><p><strong>Company</strong> refers to D96.</p></li>
        //         <li><p><strong>Goods</strong> refer to the items offered for sale on Our Service.</p></li>
        //         <li><p><strong>Orders</strong> mean a request by You to purchase Goods from Us.</p></li>
        //         <li><p><strong>Service</strong> refers to D96.</p></li>
        //         <li><p><strong>Website</strong> refers to D96, accessible from https://d-96.com.</p></li>
        //         <li><p><strong>You</strong> means the individual accessing or using the Service or the company or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p></li>
        //     </ul>

        //     <h4>Your Order Cancellation Rights</h4>
        //     <p>You are entitled to cancel Your Order within 14 days without providing any reason.</p>
        //     <p>The cancellation deadline is 14 days from the date on which You received the Goods or on which a third party, other than the carrier, takes possession of the delivered product.</p>
        //     <p>To exercise Your right of cancellation, inform Us of Your decision by:</p>
        //     <ul>
        //         <li><p>By email: support@d96.com</p></li>
        //     </ul>
        //     <p>We will reimburse You within 14 days of receiving the returned Goods, using the same payment method as You used for the Order, without incurring any fees for such reimbursement.</p>

        //     <h3>Contact Us</h3>
        //     <ul>
        //         <li><p>By email: support@d96.com</p></li>
        //     </ul>
        // </div>

        // <div style={{ padding: 30 }}>
        //     <h1>Return and Refund Policy</h1>
        //     <p>Last updated: February 02, 2024</p>
        //     <p>Thank you for shopping at D96.</p>
        //     <p>If, for any reason, You are not completely satisfied with a purchase We invite You to review our policy on refunds and returns. This Return and Refund Policy has been created with the help of the <a href="https://www.termsfeed.com/return-refund-policy-generator/" target="_blank">Return and Refund Policy Generator</a>.</p>
        //     <p>The following terms are applicable for any products that You purchased with Us.</p>
        //     <h4>Interpretation and Definitions</h4>
        //     <h3>Interpretation</h3>
        //     <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
        //     <h3>Definitions</h3>
        //     <p>For the purposes of this Return and Refund Policy:</p>
        //     <ul>
        //         <li>
        //             <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to D96.</p>
        //         </li>
        //         <li>
        //             <p><strong>Goods</strong> refer to the items offered for sale on the Service.</p>
        //         </li>
        //         <li>
        //             <p><strong>Orders</strong> mean a request by You to purchase Goods from Us.</p>
        //         </li>
        //         <li>
        //             <p><strong>Service</strong> refers to the Website.</p>
        //         </li>
        //         <li>
        //             <p><strong>Website</strong> refers to D96, accessible from <a href="https://d-96.com/" rel="external nofollow noopener" target="_blank">https://d-96.com/</a></p>
        //         </li>
        //         <li>
        //             <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
        //         </li>
        //     </ul>
        //     <h4>Your Order Cancellation Rights</h4>
        //     <p>You are entitled to cancel Your Order within 14 days without giving any reason for doing so.</p>
        //     <p>The deadline for cancelling an Order is 14 days from the date on which You received the Goods or on which a third party you have appointed, who is not the carrier, takes possession of the product delivered.</p>
        //     <p>In order to exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by:</p>
        //     <ul>
        //         <li>By email: support@d-96.com</li>
        //     </ul>
        //     <p>We will reimburse You no later than 14 days from the day on which We receive the returned Goods. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.</p>
        //     <h4>Conditions for Returns</h4>
        //     <p>In order for the Goods to be eligible for a return, please make sure that:</p>
        //     <ul>
        //         <li>The Goods were purchased in the last 14 days</li>
        //     </ul>
        //     <p>The following Goods cannot be returned:</p>
        //     <ul>
        //         <li>The supply of Goods made to Your specifications or clearly personalized.</li>
        //         <li>The supply of Goods which according to their nature are not suitable to be returned, deteriorate rapidly or where the date of expiry is over.</li>
        //         <li>The supply of Goods which are not suitable for return due to health protection or hygiene reasons and were unsealed after delivery.</li>
        //         <li>The supply of Goods which are, after delivery, according to their nature, inseparably mixed with other items.</li>
        //     </ul>
        //     <p>We reserve the right to refuse returns of any merchandise that does not meet the above return conditions in our sole discretion.</p>
        //     <p>Only regular priced Goods may be refunded. Unfortunately, Goods on sale cannot be refunded. This exclusion may not apply to You if it is not permitted by applicable law.</p>
        //     <h4>Returning Goods</h4>
        //     <p>You are responsible for the cost and risk of returning the Goods to Us. You should send the Goods at the following address:</p>
        //     <p>Cusotmer can send email support@d-96.com</p>
        //     <p>We cannot be held responsible for Goods damaged or lost in return shipment. Therefore, We recommend an insured and trackable mail service. We are unable to issue a refund without actual receipt of the Goods or proof of received return delivery.</p>
        //     <h4>Gifts</h4>
        //     <p>If the Goods were marked as a gift when purchased and then shipped directly to you, You'll receive a gift credit for the value of your return. Once the returned product is received, a gift certificate will be mailed to You.</p>
        //     <p>If the Goods weren't marked as a gift when purchased, or the gift giver had the Order shipped to themselves to give it to You later, We will send the refund to the gift giver.</p>
        //     <h3>Contact Us</h3>
        //     <p>If you have any questions about our Returns and Refunds Policy, please contact us:</p>
        //     <ul>
        //         <li>By email: support@d-96.com</li>
        //     </ul>
        // </div>
    );
};

export default RefundPolicy;