import React from "react";

export const Plans = (props) => {
  return (
    <div id="plans">
      <div className="container" style={{marginTop: 20}}>
        <div className="section-title text-center" style={{marginBottom: 0}}>
          <h2>Plans</h2>
        </div>
        <div className="container-plan">
          <Plan
            title="Basic Plan"
            features={[
              'Free Mobile App, Client Website, Admin Website',
              'Free SSL Certificate',
              'Free Domain Name',
              'Free Space: 100GB',
              'Integration with Card Payment System: $50',
              'Customize Features: Limited',
              'Update Website 5 Times/Year',
              'Free Hosting',
              'Basic Analytics',
              'Standard Customer Support',
              'Weekly Reports',
              'Email Notifications',
              'Ad-supported',
            ]}
            price="Starting at $7.99/month"
            buttonText="Choose Basic Plan"
            className="basic"
          />

          <Plan
            title="Premium Plan"
            features={[
              'Free Mobile App, Client Website, Admin Website',
              'Free SSL Certificate',
              'Free Domain Name',
              'Free Space: 500GB',
              'Integration with Card Payment System: $20',
              'Customize Features: Unlimited',
              'Update Website 10 Times/Year',
              'Free Hosting',
              'Advanced Analytics',
              'Priority Customer Support',
              'Monthly Reports',
              'SMS Notifications',
              'Ad-free Experience',
            ]}
            price="Starting at $14.99/month"
            buttonText="Choose Premium Plan"
            className="premium"
          />

          <Plan
            title="Advanced Plan"
            features={[
              'Free Mobile App, Client Website, Admin Website',
              'Free SSL Certificate',
              'Free Domain Name',
              'Free Space: 1TB',
              'Integration with Card Payment System: Free',
              'Customize Features: Unlimited',
              'Update Website Unlimited',
              'Free Hosting',
              'Advanced Analytics',
              'Priority Customer Support',
              'Admin App Access',
              'Customizable Dashboard',
              'Dedicated Account Manager',
              'API Access for Integrations',
              'Top-notch Security Features',
              'Unlimited Reports',
              'Push Notifications',
            ]}
            price="Starting at $24.99/month"
            buttonText="Choose Advanced Plan"
            className="advanced"
          />
        </div>
      </div>
    </div>
  );
};

const Plan = ({ title, features, price, buttonText, className }) => (
  <div className={`plan ${className}`}>
    <div className={`plan-header ${className}`}>{title}</div>
    <ul className={`feature-list ${className}`}>
      {features.map((feature, index) => (
        <li key={index} className={`feature ${className}`}>
          {feature}
        </li>
      ))}
    </ul>
    <div className={`price ${className}`}>{price}</div>
    <a href="#contact"><button className={`button ${className}`}>{buttonText}</button></a>
  </div>
);